
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonListHeader,
  IonLabel,

  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  personAdd,
  closeCircle,
  closeCircleOutline,
  chevronBack,
  checkmarkCircle,
  checkmarkCircleOutline,
 } from 'ionicons/icons';

import { computed, defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { ApiError, isApiError, getErrorMessage, PlanningPartner } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t from '@/translation';
import { showModal } from '@/lib/modal';
import { useForm } from 'vee-validate';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButton,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonList,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonListHeader,
    IonLabel,

    MInput,
  },
  setup(){

    const store = useStore();
    const router = useRouter();
    const showForm = ref(false);

    const plannerSchema = yup.object({
      name: yup.string().required().min(3).label(t("Name")),
      email: yup.string().email().max(50).required().label(t("Email")),
      emailConfirmation: yup.string().required(t("Please confirm the email address"))
       .oneOf([yup.ref('email')], t('Email confirmation must match')),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: plannerSchema,
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        const planner: ApiError | PlanningPartner = await store.dispatch('addPlanner', values);
        if (! isApiError(planner)){
            showForm.value = false;
            window.setTimeout(resetForm, 1000); // delayed so that validation errors are not seen before the form is hidden.
        }
      } 
    }

    async function selectWedding(weddingId: number){
      const result = await store.dispatch('selectWedding', weddingId);
      if (isApiError(result)){
          showModal(t("Error Switching Weddings"), getErrorMessage(result));
      } else {
        // if after selecting a wedding, the selected wedding is null, 
        // the user must have selected "Create My Wedding" so route to the 
        // wedding settings page
        if (! store.getters.userData.wedding){
          router.push('/tabs/home/settings/wedding')
        } 
        /* this modal is needless and annoying.
        else {
          showModal(t("Success"), t("The active wedding has been changed"));
        }
        */
      }
    }

    async function deletePlanner(plannerId: number){
      const result = await store.dispatch('removePlanner', plannerId);
      if (isApiError(result)){
          showModal(t("Error Deleting Planning Partner"), getErrorMessage(result));
      } 
    }

    onIonViewDidEnter(async () => {
      const weddingPartners = await store.dispatch("loadWeddingPartners");
      if (isApiError(weddingPartners)){
        await showModal(t("Error Fetching Planning Partners"), t("This application was unable to retreive your Planning Partners.  Please make sure you have internet access, and then login again."));
        router.push("/login");
      } 
    });

    const myWeddingIsSelected = computed(() => {
      return store.state.userData && store.state.userData.wedding && store.state.userData.my_wedding && store.state.userData.wedding.id == store.state.userData.my_wedding.id;
    });

    return {
      personAdd,
      closeCircle,
      closeCircleOutline,
      chevronBack,
      checkmarkCircle,
      checkmarkCircleOutline,

      onSubmitForm,
      selectWedding,
      deletePlanner,
      myWeddingIsSelected,
      store,
      showForm,
      resetForm,
    }
  }
});
